import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";
import { useEffect, useMemo, useState } from "react";

import { Add } from "@mui/icons-material";
import { useSearchProjectsLazyQuery } from "./generated";

export const ProjectDropdown: React.FC<{
  onSelect: (projectId: number) => void;
}> = ({ onSelect }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 700);

  const [searchProjects, { data: searchProjectsData, loading }] = useSearchProjectsLazyQuery();
  useEffect(() => {
    const nameLike = `%${(debouncedQuery ?? "").replace(/\s+/g, "%")}%`;
    searchProjects({
      variables: {
        where: { name: { _ilike: nameLike } }
      }
    });
  }, [searchProjects, debouncedQuery]);

  // memo is required to prevent reset of internal state in <Autocomplete />
  const listOptions = useMemo(() => {
    const projects = searchProjectsData?.projects ?? [];

    return projects.map(p => ({
      id: p.id,
      label: `${p.name}`
    }));
  }, [searchProjectsData?.projects]);

  const handleClose = () => {
    setQuery(null);
    setOpen(false);
  };

  return (
    <Autocomplete
      disablePortal
      options={listOptions}
      fullWidth
      onChange={(e, v) => (v?.id ? onSelect(v.id) : null)}
      value={null}
      inputValue={(open ? query : "") ?? ""}
      onInputChange={(e, v, reason) => {
        if (reason === "input") setQuery(v);
      }}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      loading={loading}
      loadingText="Loading..."
      isOptionEqualToValue={(option, v) => option.id === v.id}
      size="small"
      /* Built-in filtering needs to be disabled at lazy loaded requests */
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label={
            <span>
              <Add style={{ marginRight: 10 }} />
              Add other project
            </span>
          }
          InputLabelProps={{ style: { marginTop: -3 } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
