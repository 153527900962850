import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { TimeDurationField } from "@relatable/ui/TimeDurationField";
import { Link } from "react-router-dom";

import { Delete } from "@mui/icons-material";
import { ProjectDropdown } from "./ProjectDropdown";
import type { AccountActiveProjectsQuery, AccountTimeRecordsQuery } from "./generated";

export const HoursTable: FC<{
  activeProjects: AccountActiveProjectsQuery["projects"];
  timeRecords: AccountTimeRecordsQuery["time_tracking"];
  onUpdateHours: ({ projectId, hours }: { projectId: number; hours: string }) => void;
  onAddProject: (projectId: number) => void;
  onDeleteProject: (projectId: number) => Promise<void>;
}> = ({ activeProjects, timeRecords, onUpdateHours, onAddProject, onDeleteProject }) => {
  const [deletingProjectId, setDeletingProjectId] = useState<number | null>(null);
  const [records, setRecords] = useState<AccountTimeRecordsQuery["time_tracking"]>(
    () => timeRecords
  );

  useEffect(() => {
    const newProjectRecords = timeRecords.filter(
      t => !records.find(r => r.project.id === t.project.id)
    );
    if (newProjectRecords.length) {
      setRecords(prev => [...prev, ...newProjectRecords]);
    }
  }, [records, timeRecords]);

  const shownProjects = [...(activeProjects ?? [])];

  // add non-active but tracked projects
  records.forEach(record => {
    if (!shownProjects.find(sp => sp.id === record.project.id)) {
      shownProjects.push(record.project);
    }
  });

  const totalHours = records.reduce((acc, record) => Number(record.hours) + acc, 0) ?? 0;

  const handleUpdateHours = ({ projectId, hours }: { projectId: number; hours: string }) => {
    let normalizedHours = hours;
    if (Number(normalizedHours) < 0) {
      normalizedHours = "0";
    }
    if (Number(normalizedHours) > 16) {
      normalizedHours = "16";
    }
    const pointIndex = normalizedHours.indexOf(".");
    if (pointIndex !== -1 && normalizedHours.slice(pointIndex + 1).length > 2) {
      normalizedHours = (Math.round(Number(hours) * 100) / 100).toFixed(2);
    }

    setRecords(prev => {
      const index = prev.findIndex(t => t.project.id === projectId);
      if (index === -1) {
        const project = shownProjects.find(p => p.id === projectId);
        if (!project) throw new Error("Project not found");
        return [
          ...prev,
          {
            id: 0,
            project,
            hours: normalizedHours as unknown as number
          }
        ];
      }

      return [
        ...prev.slice(0, index),
        { ...prev[index], hours: normalizedHours as unknown as number },
        ...prev.slice(index + 1)
      ];
    });
    onUpdateHours({ projectId, hours: normalizedHours });
  };

  const handleDeleteProject = async (projectId: number) => {
    setDeletingProjectId(projectId);
    await onDeleteProject(projectId);
    setRecords(prev => prev.filter(t => t.project.id !== projectId));
  };

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: 400 }}>Project name</th>
          <th style={{ width: 150 }}>Hours</th>
          <th style={{ width: 100 }} />
        </tr>
      </thead>
      <tbody>
        {shownProjects.map(project => (
          <tr key={project.id}>
            <td>
              <Link
                to={`/overview?project=${encodeURIComponent(project.stub || "")}&archived=true&teamMemberAccountId=all`}
              >
                {project.name}
              </Link>
            </td>
            <td>
              <TimeDurationField
                hours={Number(
                  records.find(t => t.project.id === project.id)?.hours.toString() ?? "0"
                )}
                onChange={v => handleUpdateHours({ projectId: project.id, hours: v.toString() })}
              />
            </td>
            <td>
              {activeProjects.find(p => p.id === project.id) === undefined && (
                <Button
                  variant="text"
                  isLoading={deletingProjectId === project.id}
                  icon={<Delete style={{ color: palette.primary.red }} />}
                  onClick={() => handleDeleteProject(project.id)}
                >
                  Delete
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <td style={{ padding: "15px 10px" }}>
            <ProjectDropdown onSelect={onAddProject} />
          </td>
          <td />
          <td />
        </tr>
        {totalHours > 16 && (
          <tr>
            <td colSpan={2}>
              <Alert severity="warning">Total hours is over 16 hours</Alert>
            </td>
            <td />
          </tr>
        )}
        <tr>
          <td>Total</td>
          <td>{totalHours.toFixed(2)} hours</td>
          <td />
        </tr>
      </tfoot>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
  }

  th,
  td {
    padding: 8px 10px;
    border: 1px solid ${palette.gray[30]};
  }

  tbody > tr {
    &:nth-of-type(odd) td {
      background: ${palette.gray[10]};
    }
  }

  tfoot {
    td {
      font-weight: bold;
    }
  }
`;
