import { type FC, useState } from "react";

import { Badge, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import type { Campaign_User_State_Enum, Platform_Enum } from "@relatable/gql/generated-base";
import { prettifyDate, prettifyNumber } from "@relatable/helpers";
import { CONTENT_RIGHT_PERIODS } from "@relatable/helpers/constants";
import { getUserKey, getUserLabel, getUserLink, getUserPlatform } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { type AddFields, Table, type TableColumn } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { CAMPAIGN_USER_STATE_DISPLAY_NAMES, SORTED_CAMPAIGN_USER_STATES } from "lib/constants";
import { Center } from "lib/styled";
import { GigaPayStatusCell } from "modules/campaign/Participants/ParticipantsTable/GigaPayStatusCell";
import { StateCell } from "modules/campaign/common/StateCell";
import { TrackingCell } from "modules/campaign/common/TrackingCell";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";

import {
  Analytics,
  BackHand,
  Check,
  Close,
  Email,
  QuestionMark,
  ThumbDown,
  ThumbUp
} from "@mui/icons-material";
import {
  CampaignParticipantsDocument,
  type CampaignParticipantsQuery,
  CampaignParticipantsStatsDocument
} from "../generated";
import { ActionsCell } from "./ActionsCell";
import { CampaignNoteCell } from "./CampaignNoteCell";
import { PaymentCell } from "./PaymentCell";
import { ReviewPopup } from "./ReviewPopup";
import { UpdateEmailPopup } from "./UpdateEmailPopup";

export const ParticipantsTable: FC<{
  data: CampaignParticipantsQuery | undefined;
}> = ({ data }) => {
  const [reviewingCampaignUserId, setReviewingCampaignUserId] = useState<number | null>(null);
  const [updateUserEmailId, setUpdateUserEmailId] = useState<number | null>(null);
  const snackbar = useSnackbar();

  const [updateCampaignUser, { loading }] = useUpdateCampaignUserMutation({
    onCompleted: () => snackbar.success("User has been successfully updated"),
    onError: () => snackbar.error("Something went wrong"),
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsDocument, CampaignParticipantsStatsDocument]
  });
  const hasYoutubeCampaign = data?.campaigns.some(c => c.platform === "youtube");
  const hasInstagramCampaign = data?.campaigns.some(c => c.platform === "instagram");
  const hasTiktokCampaign = data?.campaigns.some(c => c.platform === "tiktok");
  const isSingleCampaign = data?.campaigns.length === 1;

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const rows =
    data?.users_in_lists.map(
      ({
        user,
        campaign_id,
        campaign_stub,
        campaign_title,
        campaign_user,
        user_id,
        user_instagram,
        user_tiktok,
        user_youtube,
        user_snapchat
      }) => {
        const campaign = data?.campaigns.find(c => c.stub === campaign_stub);
        if (!campaign?.platform) throw new Error("Invalid user platform");

        const platformFollowers: Record<Platform_Enum, number> = {
          instagram: user_instagram?.followed_by || 0,
          youtube: user_youtube?.median_views || 0,
          tiktok: user_tiktok?.followed_by || 0,
          snapchat: 0
        };

        const isNotFound: Record<Platform_Enum, boolean> = {
          instagram: Boolean(user_instagram?.is_not_found),
          youtube: Boolean(user_youtube?.is_not_found),
          tiktok: false,
          snapchat: false
        };

        const userPlatform = getUserPlatform({
          user_instagram,
          user_tiktok,
          user_youtube,
          user_snapchat
        });

        const isPlatformMismatch =
          (campaign?.platform === "instagram" && !user_instagram) ||
          (campaign?.platform === "youtube" && !user_youtube) ||
          (campaign?.platform === "tiktok" && !user_tiktok) ||
          (campaign?.platform === "snapchat" && !user_snapchat);

        const notInTheCampaign = typeof campaign_user?.id !== "number";

        if (!user_id || !campaign_id || !campaign_stub) throw new Error("Missing ids");

        const r = {
          notInTheCampaign,
          isPlatformMismatch,
          userPlatform,
          isNotFound: isNotFound[campaign.platform],

          key: `${user_id} ${campaign_user?.id}`,
          id: user_id,
          idEncoded: user?.idEncoded,
          email: user?.email,
          first_name: user?.first_name,
          platform: campaign.platform,
          currency: campaign_user?.currency,
          enumState: campaign_user?.state,
          strState: campaign_user?.state as string | null | undefined,
          intercomStatus: user?.intercom_status,
          intercomId: user?.intercom_id,
          notes: user?.notes,
          paymentStatus: campaign_user?.payment_state,
          gigapay_status: campaign_user?.gigapay_status,
          gigapay_id: user?.gigapay_id,
          gigaPayPaymentId:
            campaign_user?.gigapay_status === "HALF_PAYMENT_REQUESTED"
              ? campaign_user.gigapay_half_payment_id
              : campaign_user?.gigapay_payment_id,
          agreementPdf: campaign_user?.agreementPdf,
          encodedOnboardingUrl: campaign_user?.encodedOnboardingUrl,
          encodedAuthenticationUrl: campaign_user?.encodedAuthenticationUrl,
          encodedContentApprovalUrl: campaign_user?.encodedContentApprovalUrl,
          skipAPIVerification: campaign_user?.skip_api_verification,
          state: campaign_user
            ? campaign_user.state
              ? CAMPAIGN_USER_STATE_DISPLAY_NAMES[campaign_user.state]
              : "Not set"
            : "Not in the campaign",
          campaignStub: campaign_stub,
          language: campaign_user?.campaign?.language,
          campaignId: campaign_id,
          clientComment: campaign_user?.client_comment,
          defaultContentRightPeriod: campaign_user?.campaign.project.project_content_right?.period,
          defaultOrganicUsagePeriod: campaign_user?.campaign.project?.organic_usage_period,
          campaignTitle: campaign_title ?? "",
          targetPostCount: campaign.number_of_posts ?? 0,
          clientPriorityOrder: campaign_user?.client_priority_order,
          campaignNote: campaign_user?.notes,
          publishDate: prettifyDate(campaign_user?.publish_date),
          globalPay: campaign_user?.global_pay || 0,
          localPayment: campaign_user?.local_invoice_pay || 0,
          content_right_period: campaign_user?.content_right_period,
          organic_usage_period: campaign_user?.organic_usage_period,
          hypeAuditor: user?.hypeauditor?.state,
          campaignUserId: campaign_user?.id,
          userKey: user ? getUserKey(user) : null,
          userLabel: getUserLabel(user),
          userLink: getUserLink(user),
          tracking: campaign_user?.tracking,
          totalPostsCount:
            (user?.campaign_posts.map(i => Boolean(i.approved)).length || 0) +
            (user?.campaign_stories.map(i => Boolean(i.approved))?.length || 0),
          approvedPostsCount:
            (user?.campaign_posts.map(i => Boolean(i.approved)).filter(Boolean).length || 0) +
            (user?.campaign_stories.map(i => Boolean(i.approved))?.filter(Boolean).length || 0),
          collaboration_score: campaign_user?.collaboration_score,
          content_quality_score: campaign_user?.content_quality_score,
          agreementId: campaign_user?.scrive_agreement_id,
          agreementSigned: Boolean(campaign_user?.agreementPdf),
          validToken:
            campaign.platform === "instagram"
              ? Boolean(user_instagram?.access_token_facebook?.token_is_valid)
              : Boolean(user_tiktok?.access_tokens_tiktok?.is_valid),
          exchangeRate: campaign?.exchange_rate ?? 1,
          followers: platformFollowers[campaign.platform],
          subscribers: user_youtube?.subscribers
        };
        return r as AddFields<typeof r, "actions">;
      }
    ) || [];

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "userLabel",
      headerName: hasYoutubeCampaign && isSingleCampaign ? "Channel" : "Username",
      renderCell: ({ row }) => {
        const tooltipText = (() => {
          switch (true) {
            case row.notInTheCampaign:
              return "This creator does not belong to the campaign. Push the `Include all creators in the campaign` button above.";
            case row.isNotFound:
              return "We cannot access this creator's data.";
            case row.isPlatformMismatch:
              return `This is a(n) ${row.userPlatform} creator, but the campaign is for ${row.platform}`;
            default:
              return "";
          }
        })();

        return (
          <div style={{ display: "flex" }}>
            {!row.email && (
              <Email
                onClick={() => setUpdateUserEmailId(row.id)}
                style={{ color: palette.secondary.gold, marginRight: 5, cursor: "pointer" }}
              />
            )}
            {row.userLabel ? (
              <Link target="_blank" rel="noreferrer" to={row.userLink}>
                <Tooltip title={tooltipText}>
                  <span
                    style={{
                      color:
                        row.isNotFound || row.notInTheCampaign || row.isPlatformMismatch
                          ? "red"
                          : ""
                    }}
                  >
                    {row.userLabel} {row.first_name && `(${row.first_name})`}
                  </span>
                </Tooltip>
              </Link>
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    { field: "email", headerName: "Email" },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => <ActionsCell row={row} />
    },
    {
      field: "state",
      headerName: "Campaign status",
      minWidth: 160,
      renderCell: ({ row }) => (
        <StateCell
          platform={row.platform}
          userLabel={row.userLabel}
          campaignTitle={row.campaignTitle}
          state={row.enumState}
          campaignUserId={row.campaignUserId}
          campaignId={row.campaignId}
        />
      ),

      sortComparator: (v1, v2) => {
        const v1Order = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === v1);
        const v2Order = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === v2);
        return v1Order - v2Order;
      }
    },

    {
      field: "gigapay_status",
      minWidth: 200,
      headerName: "GigaPay status",
      renderCell: ({ value, row }) => {
        if (!value || !row.gigapay_id || !row.state) return "-";
        if (row.paymentStatus === "CUSTOM_PAYMENT_PAID") return "Paid manually";
        return (
          <GigaPayStatusCell
            campaignUserState={row.state as Campaign_User_State_Enum}
            gigaPayPaymentId={row.gigaPayPaymentId}
            campaignStub={row.campaignStub}
            gigaPayUserId={row.gigapay_id}
            paymentValue={row.localPayment}
            gigaPayStatus={value}
          />
        );
      }
    },
    {
      field: "localPayment",
      minWidth: 170,
      headerName: "Payment",
      renderCell: ({ value, row }) =>
        row.notInTheCampaign || !row.campaignUserId ? (
          "-"
        ) : (
          <PaymentCell
            followers={row.followers}
            postsCount={row.targetPostCount}
            exchangeRate={row.exchangeRate}
            currency={row.currency as string}
            campaignUserId={row.campaignUserId}
            localPay={value}
          />
        )
    },
    {
      field: "subscribers",
      headerName: "Subscribers",
      hide: !hasYoutubeCampaign,
      renderCell: ({ value }) => prettifyNumber(typeof value === "string" ? Number(value) : value)
    },
    {
      field: "followers",
      headerName: "Followers",
      hide: isSingleCampaign && hasYoutubeCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "campaignNote",
      headerName: "Campaign note",
      renderCell: ({ value, row }) =>
        row.notInTheCampaign || !row.campaignUserId ? (
          "-"
        ) : (
          <CampaignNoteCell note={value || ""} campaignUserId={row.campaignUserId} />
        )
    },
    {
      field: "validToken",
      headerName: "Business token",
      hide: !(hasInstagramCampaign || hasTiktokCampaign),
      renderCell: ({ value }) => {
        switch (value) {
          case true:
            return <Check style={{ color: palette.secondary.green }} />;
          case false:
            return (
              <Tooltip title="Token is invalid!">
                <Close style={{ color: palette.primary.gold }} />
              </Tooltip>
            );
          default:
            return (
              <Tooltip title="Creator did not go through the authorization process">
                <BackHand style={{ color: palette.secondary.gold }} />
              </Tooltip>
            );
        }
      },
      sortComparator: (a, b) => {
        const values = [undefined, false, true] as const;
        const aIndex = values.indexOf(a);
        const bIndex = values.indexOf(b);

        return aIndex - bIndex;
      }
    },
    {
      field: "intercomId",
      headerName: "Intercom",
      renderCell: ({ row }) => {
        if (row.intercomId) {
          return (
            <Link
              to={`https://app.intercom.io/a/apps/pebsfhia/conversations/${row.intercomId}`}
              target="_blank"
              rel="noreferrer"
            >
              <Email />
            </Link>
          );
        }

        if (row.intercomStatus === "not_found") {
          return (
            <Tooltip title="User not found in intercom" placement="top">
              <Link
                to="https://app.intercom.io/a/apps/pebsfhia/users"
                target="_blank"
                rel="noreferrer"
              >
                <QuestionMark />
              </Link>
            </Tooltip>
          );
        }

        return <Close style={{ color: palette.primary.gold }} />;
      },
      sortComparator: (a, b) => {
        const getValue = x => {
          if (x?.id) return 0;
          if (x?.status === "not_found") return 1;
          return 2;
        };

        return getValue(a) - getValue(b);
      }
    },
    {
      field: "totalPostsCount",
      headerName: "Posts",
      hide: !hasInstagramCampaign,
      renderCell: ({ row, value: postsCount }) => {
        const icon =
          row.approvedPostsCount >= row.targetPostCount ? (
            <Check style={{ color: palette.secondary.green }} />
          ) : (
            <Close style={{ color: palette.primary.gold }} />
          );
        if (!postsCount) return icon;

        return (
          <Badge badgeContent={postsCount || "0"} color="secondary">
            {icon}
          </Badge>
        );
      }
    },
    {
      field: "agreementSigned",
      headerName: "Signed agreement",
      renderCell: ({ value }) =>
        value ? (
          <Check style={{ color: palette.secondary.green }} />
        ) : (
          <Close style={{ color: palette.primary.gold }} />
        )
    },
    {
      field: "tracking",
      headerName: "Tracking",
      renderCell: ({ row }) => (
        <TrackingCell
          username={row.userLabel}
          campaignUserId={row.campaignUserId}
          tracking={row.tracking}
        />
      ),
      sortComparator: (a, b) => {
        const getValue = x => x?.shortlinks?.length || 0;
        return getValue(a) - getValue(b);
      }
    },
    {
      field: "content_right_period",
      headerName: "Content right period",
      minWidth: 180,
      renderCell: ({ value, row }) => (
        <Select
          disabled={loading}
          style={{ width: "100%" }}
          customNoneText="Default project settings"
          options={Object.values(CONTENT_RIGHT_PERIODS)}
          label={row.defaultContentRightPeriod && !value ? row.defaultContentRightPeriod : ""}
          value={value || ""}
          onChange={v => {
            if (!row.campaignUserId) {
              snackbar.error("Include all creators in the campaign");
              return;
            }
            updateCampaignUser({
              variables: {
                campaignUserId: row.campaignUserId,
                _set: { content_right_period: v }
              }
            });
          }}
        />
      )
    },
    {
      field: "organic_usage_period",
      headerName: "Organic usage period",
      minWidth: 180,
      renderCell: ({ value, row }) => (
        <Select
          disabled={loading}
          style={{ width: "100%" }}
          customNoneText="Default project settings"
          options={Object.values(CONTENT_RIGHT_PERIODS)}
          label={row.defaultOrganicUsagePeriod && !value ? row.defaultOrganicUsagePeriod : ""}
          value={value || ""}
          onChange={v => {
            if (!row.campaignUserId) {
              snackbar.error("Include all creators in the campaign");
              return;
            }
            updateCampaignUser({
              variables: { campaignUserId: row.campaignUserId, _set: { organic_usage_period: v } }
            });
          }}
        />
      )
    },
    {
      field: "collaboration_score",
      headerName: "Review",
      renderCell: ({ row }) => {
        if (!["approved", "confirmed"].includes(row.strState ?? "")) {
          return <span>-</span>;
        }

        if (!row.collaboration_score) {
          return (
            <Button
              variant="text"
              onClick={() => {
                if (!row.campaignUserId) return;
                setReviewingCampaignUserId(row.campaignUserId);
              }}
              disableRipple
              style={{
                minWidth: "auto",
                padding: 4,
                color: palette.secondary.gold,
                textTransform: "none",
                whiteSpace: "nowrap"
              }}
            >
              Not reviewed.
            </Button>
          );
        }

        return row.collaboration_score !== 1 ? (
          <ThumbUp
            fontSize="large"
            style={{ color: palette.primary.green, cursor: "pointer" }}
            onClick={() => {
              if (!row.campaignUserId) return;
              setReviewingCampaignUserId(row.campaignUserId);
            }}
          />
        ) : (
          <ThumbDown
            fontSize="large"
            style={{ color: palette.secondary.gold, cursor: "pointer" }}
            onClick={() => {
              if (!row.campaignUserId) return;
              setReviewingCampaignUserId(row.campaignUserId);
            }}
          />
        );
      }
    },
    {
      field: "clientComment",
      headerName: "Client comment",
      renderCell: ({ value }) => (
        <Tooltip title={value || ""}>
          <Typography style={{ wordWrap: "break-word", maxHeight: 20, overflow: "hidden" }}>
            {value || "-"}
          </Typography>
        </Tooltip>
      )
    },
    {
      field: "clientPriorityOrder",
      headerName: "Priority",
      renderCell: ({ value }) => prettifyNumber(value)
    },

    {
      field: "hypeAuditor",
      headerName: "Analytics",
      renderCell: ({ value, row }) => {
        const { platform, notInTheCampaign } = row;
        if (notInTheCampaign) return "-";

        if (!value?.length) {
          return <Analytics style={{ color: palette.gray[50] }} />;
        }
        return (
          <Link to={`/hypeauditor/${platform}/${row.id}`}>
            <Analytics style={{ color: palette.secondary.blue }} />
          </Link>
        );
      }
    }
  ];

  return (
    <>
      <Table
        tableId="campaign-participant-list"
        rows={rows}
        columns={columns}
        stickyColumn
        sortable
        canSelectColumns
        canExportCSV
      />

      {reviewingCampaignUserId !== null && (
        <ReviewPopup
          campaignUserId={reviewingCampaignUserId}
          onClose={() => setReviewingCampaignUserId(null)}
        />
      )}

      {updateUserEmailId && (
        <UpdateEmailPopup onClose={() => setUpdateUserEmailId(null)} id={updateUserEmailId} />
      )}
    </>
  );
};
