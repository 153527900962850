import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveCampaignMutationVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
  hideFromClients: Types.Scalars['Boolean']['input'];
}>;


export type ArchiveCampaignMutation = { __typename?: 'Mutation', update_campaign?: { __typename?: 'campaign_mutation_response', returning: Array<{ __typename?: 'campaign', id: number, project: { __typename?: 'project', id: number, stub: string, is_ready_for_client_review: boolean, unArchivedCampaigns: { __typename?: 'campaign_aggregate', aggregate?: { __typename?: 'campaign_aggregate_fields', count: number } | null } } }> } | null };

export type ArchiveProjectsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ArchiveProjectsMutation = { __typename?: 'Mutation', update_project?: { __typename?: 'project_mutation_response', affected_rows: number } | null };

export type CampaignReadyToArchiveQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type CampaignReadyToArchiveQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, archived?: boolean | null, title: string, hide_on_client_system?: boolean | null, campaign_timeline: Array<{ __typename?: 'campaign_timeline', id: number, end_date?: string | null }>, approvedCampaignUsers: Array<{ __typename?: 'campaign_user', id: number, notes?: string | null, collaboration_score?: number | null, content_quality_score?: number | null, state?: Types.Campaign_User_State_Enum | null, gigapay_status?: Types.Gigapay_Status_Enum | null, payment_state: Types.Payment_Status_Enum, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }>, confirmedCampaignUsers: { __typename?: 'campaign_user_aggregate', aggregate?: { __typename?: 'campaign_user_aggregate_fields', count: number } | null } }> };


export const ArchiveCampaignDocument = gql`
    mutation archiveCampaign($stub: String!, $hideFromClients: Boolean!) {
  update_campaign(
    _set: {archived: true, hide_on_client_system: $hideFromClients}
    where: {stub: {_eq: $stub}}
  ) {
    returning {
      id
      project {
        id
        stub
        is_ready_for_client_review
        unArchivedCampaigns: campaigns_aggregate(where: {archived: {_eq: false}}) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type ArchiveCampaignMutationFn = Apollo.MutationFunction<ArchiveCampaignMutation, ArchiveCampaignMutationVariables>;

/**
 * __useArchiveCampaignMutation__
 *
 * To run a mutation, you first call `useArchiveCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCampaignMutation, { data, loading, error }] = useArchiveCampaignMutation({
 *   variables: {
 *      stub: // value for 'stub'
 *      hideFromClients: // value for 'hideFromClients'
 *   },
 * });
 */
export function useArchiveCampaignMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCampaignMutation, ArchiveCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveCampaignMutation, ArchiveCampaignMutationVariables>(ArchiveCampaignDocument, options);
      }
export type ArchiveCampaignMutationHookResult = ReturnType<typeof useArchiveCampaignMutation>;
export type ArchiveCampaignMutationResult = Apollo.MutationResult<ArchiveCampaignMutation>;
export type ArchiveCampaignMutationOptions = Apollo.BaseMutationOptions<ArchiveCampaignMutation, ArchiveCampaignMutationVariables>;
export const ArchiveProjectsDocument = gql`
    mutation archiveProjects {
  update_project(
    _set: {archived: true}
    where: {archived: {_eq: false}, campaigns: {archived: {_eq: true}}, _not: {campaigns: {archived: {_eq: false}}}}
  ) {
    affected_rows
  }
}
    `;
export type ArchiveProjectsMutationFn = Apollo.MutationFunction<ArchiveProjectsMutation, ArchiveProjectsMutationVariables>;

/**
 * __useArchiveProjectsMutation__
 *
 * To run a mutation, you first call `useArchiveProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectsMutation, { data, loading, error }] = useArchiveProjectsMutation({
 *   variables: {
 *   },
 * });
 */
export function useArchiveProjectsMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProjectsMutation, ArchiveProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProjectsMutation, ArchiveProjectsMutationVariables>(ArchiveProjectsDocument, options);
      }
export type ArchiveProjectsMutationHookResult = ReturnType<typeof useArchiveProjectsMutation>;
export type ArchiveProjectsMutationResult = Apollo.MutationResult<ArchiveProjectsMutation>;
export type ArchiveProjectsMutationOptions = Apollo.BaseMutationOptions<ArchiveProjectsMutation, ArchiveProjectsMutationVariables>;
export const CampaignReadyToArchiveDocument = gql`
    query campaignReadyToArchive($stub: String!) {
  campaign(where: {stub: {_eq: $stub}}) {
    id
    archived
    title
    campaign_timeline {
      id
      end_date
    }
    hide_on_client_system
    approvedCampaignUsers: campaign_users(where: {state: {_eq: "approved"}}) {
      id
      notes
      collaboration_score
      content_quality_score
      state
      gigapay_status
      payment_state
      user {
        ...UserInfo
      }
    }
    confirmedCampaignUsers: campaign_users_aggregate(
      where: {state: {_eq: "confirmed"}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCampaignReadyToArchiveQuery__
 *
 * To run a query within a React component, call `useCampaignReadyToArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignReadyToArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignReadyToArchiveQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useCampaignReadyToArchiveQuery(baseOptions: Apollo.QueryHookOptions<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables> & ({ variables: CampaignReadyToArchiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>(CampaignReadyToArchiveDocument, options);
      }
export function useCampaignReadyToArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>(CampaignReadyToArchiveDocument, options);
        }
export function useCampaignReadyToArchiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>(CampaignReadyToArchiveDocument, options);
        }
export type CampaignReadyToArchiveQueryHookResult = ReturnType<typeof useCampaignReadyToArchiveQuery>;
export type CampaignReadyToArchiveLazyQueryHookResult = ReturnType<typeof useCampaignReadyToArchiveLazyQuery>;
export type CampaignReadyToArchiveSuspenseQueryHookResult = ReturnType<typeof useCampaignReadyToArchiveSuspenseQuery>;
export type CampaignReadyToArchiveQueryResult = Apollo.QueryResult<CampaignReadyToArchiveQuery, CampaignReadyToArchiveQueryVariables>;