import { InputAdornment, TextField, type TextFieldProps } from "@mui/material";
import type React from "react";
import { type SyntheticEvent, useCallback, useEffect, useRef } from "react";

import { Refresh } from "@mui/icons-material";
import { useLatest } from "@relatable/ui/hooks/useLatest";
import { useStoredState } from "@relatable/ui/hooks/useStoredState";

const makeTimeStr = (hours: number, minutes: number) => {
  const hoursStr = Math.round(hours).toString().padStart(2, "0");
  const normalizedMinutes = Math.min(Math.round(minutes), 59);
  const minutesStr = normalizedMinutes.toString().padStart(2, "0");
  return `${hoursStr}h:${minutesStr}m`;
};

const parseTimeStr = (str: string) => {
  const m = str.match(/^([0-9]+)h:([0-9]+)m$/);
  if (!m) return null;
  const hours = Number(m[1]);
  let minutes = Number(m[2]);
  if (minutes > 59) {
    minutes = 59;
  }
  return { hours, minutes };
};

const parseFractionalHours = (hours: number) => {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return { hours: h, minutes: m };
};

const toFractionalHours = (hours: number, minutes: number) => {
  return hours + minutes / 60;
};

export const TimeDurationField: React.FC<{
  onChange: (hours: number) => void;
  hours: number;
  label?: string;
  style?: React.CSSProperties;
  inputProps?: TextFieldProps["inputProps"];
}> = ({ onChange, hours, label, style, inputProps }) => {
  const [fractionalMode, setFractionalMode] = useStoredState("fractional-duration", false);
  const latestOnChange = useLatest(onChange);
  const inputRef = useRef<HTMLInputElement>(null);
  const insertPosition = useRef<number>(0);

  const setValue = useCallback(
    (str: string) => {
      if (!inputRef.current) return;
      if (fractionalMode) {
        const h = Number(str);
        if (Number.isNaN(h)) return;
        latestOnChange.current?.(h);
      } else {
        const parts = parseTimeStr(str);
        if (!parts) return;
        inputRef.current.value = str;
        latestOnChange.current?.(toFractionalHours(parts.hours, parts.minutes));
      }
    },
    [latestOnChange, fractionalMode]
  );

  const setSelection = useCallback((type: "hours" | "minutes") => {
    if (!inputRef.current) return;

    const colon = inputRef.current.value.indexOf(":");
    if (colon === -1) return;

    if (type === "hours") {
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = colon - 1;
    } else {
      inputRef.current.selectionStart = colon + 1;
      inputRef.current.selectionEnd = inputRef.current.value.length - 1;
    }
  }, []);

  useEffect(() => {
    if (!inputRef.current) return;
    if (fractionalMode) {
      const str = hours.toString();
      if (inputRef.current.value !== str) {
        inputRef.current.value = str;
      }
    } else {
      const parsedHours = parseFractionalHours(hours);
      const str = makeTimeStr(parsedHours.hours, parsedHours.minutes);
      // avoid selection change
      if (inputRef.current.value !== str) {
        inputRef.current.value = str;
        setSelection("hours");
      }
    }
  }, [hours, setSelection, fractionalMode]);

  const getCurrentSelection = useCallback(() => {
    if (!inputRef.current) return null;
    const colon = inputRef.current.value.indexOf(":");
    if (colon === -1) return null;
    if (inputRef.current.selectionStart === 0 && inputRef.current.selectionEnd === colon - 1) {
      return "hours";
    }
    if (
      inputRef.current.selectionStart === colon + 1 &&
      inputRef.current.selectionEnd === inputRef.current.value.length - 1
    ) {
      return "minutes";
    }
    return null;
  }, []);

  const handleSelect = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement, Event>) => {
      if (fractionalMode) return;

      const selection = getCurrentSelection();
      if (selection) return;

      const colon = e.currentTarget.value.indexOf(":");
      if (colon === -1) return;

      if ((e.currentTarget.selectionStart ?? 0) <= colon) {
        setSelection("hours");
      } else {
        setSelection("minutes");
      }
    },
    [getCurrentSelection, setSelection, fractionalMode]
  );

  const handleClick = useCallback(() => {
    if (fractionalMode) return;
    insertPosition.current = 0;
  }, [fractionalMode]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (fractionalMode) return;

      const selection = getCurrentSelection();

      // it should allow jumping out of field
      if (!(e.key === "Tab" && selection === "minutes")) {
        e.preventDefault();
      }

      if (["ArrowRight", "Tab"].includes(e.key) && e.currentTarget.selectionStart === 0) {
        insertPosition.current = 0;
        setSelection("minutes");
      } else if (
        e.key === "ArrowLeft" &&
        e.currentTarget.selectionEnd === e.currentTarget.value.length - 1
      ) {
        insertPosition.current = 0;
        setSelection("hours");
      } else if (e.key.match(/^[0-9]+$/)) {
        const parts = parseTimeStr(e.currentTarget.value);
        if (!parts) return;
        if (selection === "hours") {
          const h = parts.hours.toString().split("");
          h[insertPosition.current] = e.key;
          setValue(
            makeTimeStr(Number(h.slice(0, insertPosition.current + 1).join("")), parts.minutes)
          );
          setSelection("hours");
          insertPosition.current++;
        } else {
          const m = parts.minutes.toString().split("");
          m[insertPosition.current] = e.key;
          setValue(
            makeTimeStr(parts.hours, Number(m.slice(0, insertPosition.current + 1).join("")))
          );
          setSelection("minutes");
          insertPosition.current = (insertPosition.current + 1) % 2;
        }
      }
    },
    [getCurrentSelection, setValue, setSelection, fractionalMode]
  );

  const switchMode = useCallback(() => {
    setFractionalMode(prev => !prev);
  }, [setFractionalMode]);

  const adornment = (
    <InputAdornment
      position="end"
      onClick={switchMode}
      title={`Switch to ${fractionalMode ? "formatted" : "fractional"} representation`}
    >
      <Refresh style={{ cursor: "pointer" }} />
    </InputAdornment>
  );

  const labelWithDefault = label ?? "Duration";

  return (
    <TextField
      type={fractionalMode ? "number" : undefined}
      label={fractionalMode ? `${labelWithDefault} (hours)` : labelWithDefault}
      inputRef={inputRef}
      InputProps={{
        endAdornment: adornment
      }}
      style={style ?? {}}
      onChange={fractionalMode ? e => setValue(e.target.value) : undefined}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        onClick: handleClick,
        onSelect: handleSelect,
        onKeyDown: handleKeyDown,
        ...(inputProps ?? {})
      }}
    />
  );
};
