import { type FC, useState } from "react";

import styled from "@emotion/styled";

import { Box, CircularProgress, Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { getCampaignProgress, numberWithCommas, prettifyNumber, sum } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { useVerticalsQuery } from "modules/generated";
import type { OverviewQuery } from "modules/overview/generated";
import type { InfluencerMarketingBudget } from "src/typings/global";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useMarketsQuery } from "@relatable/gql/generated";
import { MetricsContainer } from "./MetricsContainer";
import { type OverviewCampaignQuery, useOverviewCampaignLazyQuery } from "./generated";

type CampaignRowProps = {
  partialCampaign: OverviewQuery["clients"][number]["projects"][number]["campaigns_aggregate"]["nodes"][number];
  influencerMarketingBudget: InfluencerMarketingBudget;
};

export const CampaignRow: FC<CampaignRowProps> = ({
  partialCampaign: {
    platform,
    max_budget,
    market_ids,
    vertical_ids,
    campaign_participants_aggregate,
    archived,
    ...partialCampaign
  },
  influencerMarketingBudget
}) => {
  if (!Array.isArray(market_ids)) throw Error("market_ids should be number[]");
  if (!Array.isArray(vertical_ids)) throw Error("vertical_ids should be number[]");

  const [open, setOpen] = useState(false);
  const verticalsQuery = useVerticalsQuery();
  const marketsQuery = useMarketsQuery();

  const [fetchCampaign, { data, loading: fetchCampaignLoading }] = useOverviewCampaignLazyQuery({
    variables: { campaignId: partialCampaign.id }
  });

  const {
    approved_campaign_posts_aggregate,
    approved_campaign_stories_aggregate,
    campaign_participants = [],
    number_of_posts = 0,
    target_participants = 0,
    target_reach = 0
  } = data?.campaign || {};

  const budgetUsed = campaign_participants_aggregate?.aggregate?.sum?.global_pay || 0;
  const participantsCount = campaign_participants_aggregate?.aggregate?.count || 0;

  const getFollowers = (
    user: NonNullable<OverviewCampaignQuery["campaign"]>["campaign_participants"][number]["user"]
  ) => {
    switch (platform) {
      case "instagram":
        return user?.user_instagram?.followed_by || 0;
      case "youtube":
        return Number(user?.user_youtube?.subscribers) || 0;
      case "tiktok":
        return user?.user_tiktok?.followed_by || 0;
      case "snapchat":
        return 0;
      default:
        throw new Error(`Invalid platform ${platform}`);
    }
  };

  const { participantsReach, videosCount } = campaign_participants.reduce(
    (acc, { user, campaign_videos = [] }) => ({
      ...acc,
      participantsReach: acc.participantsReach + getFollowers(user),
      videosCount: acc.videosCount + campaign_videos.length
    }),
    { participantsReach: 0, videosCount: 0 }
  );

  const progress = getCampaignProgress(partialCampaign.campaign_timeline ?? []) ?? 0;

  return (
    <>
      <StyledTableRow open={open}>
        <ExpandCampaignTableCell>
          <IconButton onMouseEnter={() => !open && fetchCampaign()} onClick={() => setOpen(!open)}>
            {open ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        </ExpandCampaignTableCell>
        <TableCell component="th" scope="row">
          <Link
            style={{ color: "inherit", textDecoration: "underline" }}
            to={`/campaign/${partialCampaign.stub}`}
          >
            {partialCampaign.title}
          </Link>
        </TableCell>
        <TableCell>{archived ? "Archived" : ""}</TableCell>
        <TableCell>{prettifyNumber(progress, { percentages: true })}</TableCell>
        <TableCell>
          {market_ids.map(id => marketsQuery.data?.market.find(m => m.id === id)?.name).join(", ")}
        </TableCell>
        <TableCell>
          {vertical_ids
            .map(id => verticalsQuery.data?.vertical.find(v => v.id === id)?.name)
            .join(", ")}
        </TableCell>
        <TableCell>
          {prettifyNumber(
            sum(
              partialCampaign.campaign_users.map(
                cu =>
                  cu.user?.user_instagram?.followed_by ||
                  cu.user?.user_tiktok?.followed_by ||
                  Number(cu.user?.user_youtube?.subscribers || 0) ||
                  0
              )
            ) / partialCampaign.campaign_users.length,
            { rounded: true }
          )}
        </TableCell>
        <TableCell align="right">{numberWithCommas(max_budget)}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open &&
              (data?.campaign && !fetchCampaignLoading ? (
                <Box margin={3}>
                  <MetricsContainer
                    budgetUsed={budgetUsed}
                    liveContentCount={
                      platform === "instagram" &&
                      approved_campaign_stories_aggregate &&
                      approved_campaign_posts_aggregate
                        ? (approved_campaign_posts_aggregate?.aggregate?.count || 0) +
                          (approved_campaign_stories_aggregate?.aggregate?.count || 0)
                        : videosCount
                    }
                    max_budget={max_budget || 0}
                    target_participants={target_participants || 0}
                    number_of_posts={number_of_posts || 0}
                    participantsCount={participantsCount}
                    participantsReach={participantsReach * (number_of_posts || 0)}
                    target_reach={target_reach || 0}
                    influencerMarketingBudget={influencerMarketingBudget}
                  />
                </Box>
              ) : (
                <CircularProgress />
              ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ExpandCampaignTableCell = styled(TableCell)`
  && {
    width: 1em;
    padding: 6px 0px 6px 16px;
  }

  button {
    height: 1em;
    width: 1em;
  }
`;

const StyledTableRow = styled(TableRow)<{ open: boolean }>`
  && {
    ${({ open }) => open && `background-color: ${palette.tertiary.red}`}

    td {
      border-bottom: 0px;
    }
  }
`;
