import type { FC } from "react";

import styled from "@emotion/styled";

import { Alert } from "@mui/material";
import { isBefore } from "@relatable/helpers/date";
import { getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useSlack } from "hooks/useSlack";
import { useUser } from "hooks/useUser";
import { useEnableClientReviewAndSendEmailsMutation } from "modules/project/ProjectUpdate/generated";

import { HideSource } from "@mui/icons-material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Note } from "@relatable/ui/Note";
import {
  useArchiveCampaignMutation,
  useArchiveProjectsMutation,
  useCampaignReadyToArchiveQuery
} from "./generated";

export const Archive: FC = () => {
  useDocumentTitle("Archive campaign");
  const params = useParams<{ campaignStub: string }>();
  const navigate = useNavigate();
  const user = useUser();

  const { data, loading } = useCampaignReadyToArchiveQuery({
    variables: { stub: params.campaignStub ?? "" }
  });
  const campaign = data?.campaign?.[0];

  const approvedUsersLackingReview = campaign?.approvedCampaignUsers.filter(
    u => !u.collaboration_score
  );

  const shouldBlockDueToActiveGigaPayUsers = campaign?.approvedCampaignUsers.some(
    u =>
      u.state === "approved" &&
      u.payment_state !== "CUSTOM_PAYMENT" &&
      u.payment_state !== "CUSTOM_PAYMENT_PAID" &&
      !["PAYMENT_REQUESTED", "MONEY_SENT"].includes(String(u.gigapay_status))
  );

  const hasUnfinishedDeadlines = campaign?.campaign_timeline.some(
    t => t.end_date && isBefore(new Date(), t.end_date)
  );

  const [archiveCampaign, { loading: archiveCampaignLoading }] = useArchiveCampaignMutation();
  const [archiveProjects, { loading: archiveProjectsLoading }] = useArchiveProjectsMutation();
  const [enableClientReviewAndSendEmails, { loading: enableClientReviewAndSendEmailsLoading }] =
    useEnableClientReviewAndSendEmailsMutation({});
  const { sendSlackNotification, loading: isSlacking } = useSlack();

  const handleArchive = async ({ hideFromClients }: { hideFromClients: boolean }) => {
    if (!campaign) throw Error("Campaign not found");

    if (!campaign.archived)
      await sendSlackNotification({
        variables: {
          campaignId: campaign.id,
          channel: "archived-campaigns",
          text: `Campaign ${campaign?.title} has been archived by ${user?.full_name}.`
        }
      });

    const archivedCampaignQuery = await archiveCampaign({
      variables: { stub: params.campaignStub ?? "", hideFromClients }
    });

    const p = archivedCampaignQuery.data?.update_campaign?.returning[0]?.project;
    if (p?.unArchivedCampaigns.aggregate?.count === 0) {
      await Promise.all([
        archiveProjects(),
        !p.is_ready_for_client_review &&
          enableClientReviewAndSendEmails({ variables: { projectStub: p.stub } })
      ]);
    }

    navigate("/");
  };

  if (!params.campaignStub) return null;

  return (
    <Root>
      <Note variant="info" label="Further content updates">
        Archiving the campaign stops automated updates of campaign, participants and their content.
      </Note>
      <Note variant="info" label="What happens to project">
        When the last campaign is archived, the project also gets archived.
      </Note>

      {(campaign?.confirmedCampaignUsers.aggregate?.count || 0) > 0 && (
        <Note variant="warning" label="What about approving the confirmed participants first?">
          There are confirmed participants which were not approved. Are you sure?
        </Note>
      )}

      {Boolean(approvedUsersLackingReview?.length) && (
        <Alert variant="filled" severity="error">
          There are approved participants lacking review. <br />
          Users lacking review:
          <ul>
            {approvedUsersLackingReview?.map(u => (
              <li key={u.id}>{getUserLabel(u.user)}</li>
            ))}
          </ul>
          Click <Link to={`/campaign/${params.campaignStub}/participants`}>here</Link> to add
          review.
        </Alert>
      )}

      {shouldBlockDueToActiveGigaPayUsers && (
        <Alert variant="filled" severity="error">
          There are approved participants without Gigapay onboarding.
        </Alert>
      )}

      {hasUnfinishedDeadlines && (
        <Alert variant="filled" severity="error">
          Check the timeline. Not all deadlines have passed. Whether move the timelines to the past
          or archive the campaign later.
        </Alert>
      )}

      {campaign?.archived && (
        <Alert variant="filled" severity="success">
          The campaign has been successfully archived{" "}
          {campaign.hide_on_client_system ? "and hidden" : ""}.
        </Alert>
      )}

      <div style={{ display: "flex", gap: 15 }}>
        <Button
          tooltipText="This will archive the campaign and hide it from clients in workspace."
          size="medium"
          color="error"
          icon={<HideSource />}
          onClick={() => handleArchive({ hideFromClients: true })}
          disabled={
            shouldBlockDueToActiveGigaPayUsers ||
            Boolean(approvedUsersLackingReview?.length) ||
            Boolean(campaign?.hide_on_client_system)
          }
          isLoading={
            archiveCampaignLoading ||
            archiveProjectsLoading ||
            loading ||
            enableClientReviewAndSendEmailsLoading ||
            isSlacking
          }
        >
          Archive and hide
        </Button>

        <Button
          size="medium"
          color="info"
          icon={<ArchiveIcon />}
          onClick={() => handleArchive({ hideFromClients: false })}
          disabled={
            shouldBlockDueToActiveGigaPayUsers ||
            Boolean(approvedUsersLackingReview?.length) ||
            Boolean(campaign?.archived) ||
            hasUnfinishedDeadlines
          }
          isLoading={
            archiveCampaignLoading ||
            archiveProjectsLoading ||
            loading ||
            enableClientReviewAndSendEmailsLoading ||
            isSlacking
          }
        >
          Archive
        </Button>
      </div>
    </Root>
  );
};

const Root = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
